<template>
  <div class="h-screen bg-gradient-to-br from-gray-600 to-gray-800 flex flex-col items-center w-full">
    <div v-if="loading" class="w-screen max-w-sm my-5">
      <p class="text-white">LOADING</p>
    </div>
    <div v-if="events && !loading">
      <h1 class="text-white">Select the Race session to create a grid for:</h1>
      <SelectSession
        :axios="axiosInstance"
        @session="onSelectSession"
        @event="onSelectEvent"
        @sessions="(s) => (sessions = s)"
        :events="events"
        :loading="loading"
        :error="error"
      />
    </div>
    <div v-if="sessionId">
      <Error v-if="error" :message="error" />
      <div>
        <h1 class="text-white">Qualifying session to base the grid on:</h1>
        <select
          class="form-select appearance-none block w-full px-3 py-1.5 text-base font-normal text-gray-700 bg-white bg-clip-padding bg-no-repeat border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
          aria-label="Select a qualifying session"
          v-model="qualifyingSessionId"
        >
          <option :value="null">Select a Qualifying session</option>
          <option v-for="session in sessions" :key="session.id" :value="session.id">{{ session.name }}</option>
        </select>
      </div>
      <div>
        <h1 class="text-white">Select a Race session to base the grid on:</h1>
        <select
          class="form-select appearance-none block w-full px-3 py-1.5 text-base font-normal text-gray-700 bg-white bg-clip-padding bg-no-repeat border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
          aria-label="Select a race session to base the grid on"
          v-model="raceSessionId"
        >
          <option :value="null">Select a Race session</option>
          <option v-for="session in sessions" :key="session.id" :value="session.id">{{ session.name }}</option>
        </select>
      </div>
      <div class="form-check">
        <input
          xclass="form-check-input appearance-none h-4 w-4 border border-gray-300 rounded-sm bg-white checked:bg-blue-600 checked:border-blue-600 focus:outline-none transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer"
          type="checkbox"
          v-model="groupBy"
          true-value="Class"
          false-value=""
          style="margin-right: 5px;"
          id="groupBy"
        />
        <label class="form-check-label inline-block text-white" for="groupBy"> Group by class</label>
      </div>
      <button
        :disabled="gridding.loading || !eventId || !sessionId || !qualifyingSessionId || !raceSessionId"
        @click.prevent="onCreateGrid"
        :class="{ disabled: gridding.loading, 'cursor-not-allowed': gridding.loading }"
        class="mt-4 tracking-wide w-full text-lg inline-flex items-center px-4 py-2 font-semibold leading-6 text-sm shadow rounded-md text-white bg-gradient-to-tr from-blue-600 to-indigo-600 hover:bg-indigo-400 transition ease-in-out duration-150"
      >
        <svg
          class="animate-spin -ml-1 mr-3 h-5 w-5 text-white"
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          v-if="gridding.loading"
          viewBox="0 0 24 24"
        >
          <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
          <path
            class="opacity-75"
            fill="currentColor"
            d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
          ></path>
        </svg>
        {{ buttonTitle }}
      </button>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import SelectSession from './SelectSession.vue'
import Error from './Error.vue'

const BUTTON_TITLE = 'Generate Grid'

export default {
  components: {
    SelectSession,
    Error,
  },
  data() {
    return {
      axiosInstance: axios.create({ baseURL: 'https://reporting.usacnation.com' }),
      eventId: null,
      sessionId: null,
      qualifyingSessionId: null,
      raceSessionId: null,
      groupBy: 'Class',
      events: [],
      sessions: [],
      error: '',
      loading: true,
      session: { data: null, loading: true, error: null },
      buttonTitle: BUTTON_TITLE,
      gridding: {
        data: null,
        error: null,
        loading: false,
      },
    }
  },
  created() {
    this.axiosInstance
      .get('/events')
      .then((response) => {
        this.events = response.data
        this.loading = false
      })
      .catch((error) => {
        console.error(error)
        this.error = error.toString()
        this.loading = false
      })
  },
  methods: {
    onSelectEvent(eventId) {
      this.eventId = eventId
    },
    async fetchSession(id) {
      try {
        this.session.error = null
        this.session.data = null
        this.session.has = null
        this.session.loading = true

        const url = `/events/${this.eventId}/sessions/${id}`
        const { data } = await this.axiosInstance.get(url)
        this.session.data = data
      } catch (e) {
        this.session.error = e.toString()
      } finally {
        this.session.loading = false
      }
    },
    async onSelectSession({ id }) {
      this.sessionId = id
      this.fetchSession(id)
    },
    onCreateGrid() {
      const { eventId, sessionId, qualifyingSessionId, raceSessionId, groupBy } = this
      this.gridding.error = null
      this.gridding.loading = true
      const config = {
        params: {
          qualifyingSessionId,
          raceSessionId,
          groupBy,
        },
      }
      const url = `/events/${eventId}/sessions/${sessionId}/grid`
      let buttonTitle
      axios
        .post(url, { groupBy }, config)
        .then((response) => {
          this.gridding.data = response.data
          this.gridding.loading = false
          buttonTitle = `Grid uploaded to gridding tool!`
        })
        .catch((error) => {
          console.error(error)
          buttonTitle = error.toString()
          this.gridding.error = error.toString()
          this.gridding.loading = false
        })
        .finally(() => {
          this.buttonTitle = buttonTitle
          setTimeout(() => {
            this.buttonTitle = BUTTON_TITLE
          }, 5000)
        })
    },
  },
}
</script>

<style></style>
