<template>
  <div class="h-screen bg-gradient-to-br from-gray-600 to-gray-800 flex flex-col items-center w-full">
    <div v-if="state === 'SelectSession' && loading" class="w-screen max-w-sm my-5">
      <p class="text-white">LOADING</p>
    </div>
    <SelectSession
      v-if="events && !loading"
      :axios="axiosInstance"
      @session="onSelectSession"
      @event="onSelectEvent"
      :events="events"
      :loading="loading"
      :error="error"
    />
    <div v-if="selectedSessionId && reports">
      <form>
        <Error v-if="error" :message="error" />
        <div class="bg-white px-10 py-8 rounded-none sm:rounded-xl w-screen shadow-md max-w-2xl">
          <div class="space-y-4">
            <h1 class="text-center text-2xl font-semibold text-gray-600">USAC Session reporting</h1>
            <div>
              <label for="eventTitle" class="block mb-1 text-left text-gray-600 font-semibold">Event title*</label>
              <input
                id="eventTite"
                v-model="eventTitle"
                required
                type="text"
                class="bg-indigo-50 px-4 py-2 outline-none rounded-md w-full"
                placeholder="NOLA Motorsports Park"
              />
            </div>
            <div>
              <label for="eventSubtitle" class="block mb-1 text-left text-gray-600 font-semibold">Event subtitle</label>
              <input
                id="eventSubtitle"
                v-model="eventSubtitle"
                required
                type="text"
                class="bg-indigo-50 px-4 py-2 outline-none rounded-md w-full"
                placeholder="February 24, 2025 / Corona, CA"
              />
            </div>
            <div>
              <label for="name" class="block mb-1 text-left text-gray-600 font-semibold">Session title*</label>
              <input
                id="name"
                v-model="sessionTitle"
                required
                type="text"
                class="bg-indigo-50 px-4 py-2 outline-none rounded-md w-full"
                placeholder="GT3 Cup - Race 1"
              />
            </div>
          </div>
          <div>
            <label class="block mt-5 mb-1 text-left text-gray-600 font-semibold">Select your reports</label>
            <div class="grid md:grid-cols-3 gap-0 sm:grid-cols-1">
              <div v-for="report in reports" :key="report.filename" class="flex items-center items-start mb-4">
                <input
                  :id="report.filename"
                  :aria-describedby="report.name"
                  type="checkbox"
                  class="bg-gray-50 border-gray-300 focus:ring-3 focus:ring-blue-300 h-4 w-4 rounded"
                  :value="report.filename"
                  v-model="reportsToGenerate"
                />
                <label :for="report.filename" class="text-sm ml-3 font-medium text-gray-900">{{ report.name }}</label>
              </div>
            </div>
          </div>

          <div
            v-if="
              reportsToGenerate.includes('reports/starting-grid.html') ||
              reportsToGenerate.includes('reports/race-summary.html')
            "
          >
            <label class="block mt-5 mb-1 text-left text-gray-600 font-semibold">Report configuration</label>
          </div>
          <div v-if="reportsToGenerate.includes('reports/starting-grid.html')">
            <div class="flex">
              <div class="pr-5">Create Race 2 Grid</div>
              <div class="form-check form-check-inline pr-5">
                <router-link to="/gridding">Gridding tool</router-link>
              </div>
            </div>
          </div>
          <div v-if="reportsToGenerate.includes('reports/starting-grid.html')">
            <div class="flex">
              <div class="pr-5">Pole Position</div>
              <div class="form-check form-check-inline pr-5">
                <input
                  class="form-check-input form-check-input appearance-none rounded-full h-4 w-4 border border-gray-300 bg-white checked:bg-blue-600 checked:border-blue-600 focus:outline-none transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer"
                  type="radio"
                  v-model="config.polePosition"
                  name="inlineRadioOptions"
                  id="inlineRadio1"
                  value="left"
                />
                <label class="form-check-label inline-block text-gray-800" for="inlineRadio10">Left</label>
              </div>
              <div class="form-check form-check-inline">
                <input
                  class="form-check-input form-check-input appearance-none rounded-full h-4 w-4 border border-gray-300 bg-white checked:bg-blue-600 checked:border-blue-600 focus:outline-none transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer"
                  type="radio"
                  v-model="config.polePosition"
                  name="inlineRadioOptions"
                  id="inlineRadio2"
                  value="right"
                />
                <label class="form-check-label inline-block text-gray-800" for="inlineRadio20">Right</label>
              </div>
            </div>
          </div>

          <div v-if="reportsToGenerate.includes('reports/race-summary.html') || reportsToGenerate.includes('reports/sro-race-summary.html')">
            <div class="flex">
              <div class="pr-5">Points</div>
              <div class="flex items-center items-start mb-4">
                <input
                  id="include-points"
                  type="checkbox"
                  class="bg-gray-50 border-gray-300 focus:ring-3 focus:ring-blue-300 h-4 w-4 rounded"
                  v-model="config.includePoints"
                />
                <label for="include-points" class="text-sm ml-3 font-medium text-gray-900">Include Points?</label>
              </div>
            </div>
            <div class="flex">
              <div class="pr-5">Positions Gained</div>
              <div class="flex items-center items-start mb-4">
                <input
                  id="positions-gained-per-class"
                  type="checkbox"
                  class="bg-gray-50 border-gray-300 focus:ring-3 focus:ring-blue-300 h-4 w-4 rounded"
                  v-model="config.includePositionsGainedPerClass"
                />
                <label for="positions-gained-per-class" class="text-sm ml-3 font-medium text-gray-900"
                  >Include Positions Gained (per class)</label
                >
              </div>
            </div>
          </div>

          <div
            v-if="
              reportsToGenerate.includes('reports/race-summary.html') ||
              reportsToGenerate.includes('reports/sro-race-summary.html') ||
              reportsToGenerate.includes('reports/qualifying-summary.html')
            "
          >
            <div class="flexx">
              <div class="pr-5">Announcements</div>

              <textarea
                class="form-control block w-full px-3 py-1.5 text-base font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
                v-model="config.announcements"
              />
            </div>
          </div>

          <div
            v-if="
              reportsToGenerate.includes('reports/starting-grid.html')
            "
          >
            <div class="flexx">
              <div class="pr-5">Grid Announcements</div>

              <textarea
                class="form-control block w-full px-3 py-1.5 text-base font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
                v-model="config.gridAnnouncements"
              />
            </div>
          </div>

          <div :key="selectedSessionId" v-if="reportsToGenerate.length">
            <label class="block mt-3 mb-1 text-left text-gray-600 font-semibold">Orbits exports needed</label>
            <form
              :action="`/events/${selectedEventId}/sessions/${selectedSessionId}/assets`"
              method="post"
              enctype="multipart/form-data"
            >
              <div v-for="asset in assets" :key="asset.key">
                <div v-if="assetsNeeded.includes(asset.key)">
                  <label class="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300 mt-3" :xfor="asset.key"
                    ><span class="text-green-600 pr-2">{{
                      session && session.has && session.has[asset.key] ? '(Uploaded)' : ''
                    }}</span
                    ><span class="pr-1">{{ asset.name }}</span>
                    <svg
                      class="w-4 h-4 inline"
                      fill="none"
                      stroke="currentColor"
                      viewBox="0 0 24 24"
                      xmlns="http://www.w3.org/2000/svg"
                      @click="onHelp(asset.modal, asset.key)"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        d="M8.228 9c.549-1.165 2.03-2 3.772-2 2.21 0 4 1.343 4 3 0 1.4-1.278 2.575-3.006 2.907-.542.104-.994.54-.994 1.093m0 3h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                      ></path>
                    </svg>
                  </label>
                  <input
                    class="block w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 cursor-pointer dark:text-gray-400 focus:outline-none focus:border-transparent dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400"
                    aria-describedby="user_avatar_help"
                    :name="asset.key"
                    @change="onFilesChange"
                    :key="`session-${sessionId}-file-${asset.key}`"
                    type="file"
                  />
                </div>
              </div>
            </form>
          </div>
          <div v-if="Array.isArray(downloadLinks) && downloadLinks.length">
            <label class="block mt-3 mb-1 text-left text-gray-600 font-semibold"
              >Download Reports (<a href="#" @click.prevent="refreshFilesCache">{{
                refreshingFilesCache ? 'Refreshing' : 'Refresh files site cache'
              }}</a
              >)</label
            >
            <ul>
              <li v-for="link in downloadLinks" :key="link.name">
                <a class="text-indigo-600 underline" :href="link.url" target="_blank">{{ link.name }}</a>
                <a v-if="link.pdfUrl" class="text-indigo-600 underline ml-5" :href="link.pdfUrl" target="_blank"
                  >(PDF)</a
                >
              </li>
            </ul>
          </div>
          <button
            v-if="reportsToGenerate.length"
            :disabled="loading || !reportsToGenerate.length || !sessionTitle || !eventTitle"
            @click.prevent="onGenerate"
            :class="{ disabled: loading, 'cursor-not-allowed': loading }"
            class="mt-4 tracking-wide w-full text-lg inline-flex items-center px-4 py-2 font-semibold leading-6 text-sm shadow rounded-md text-white bg-gradient-to-tr from-blue-600 to-indigo-600 hover:bg-indigo-400 transition ease-in-out duration-150"
          >
            <svg
              class="animate-spin -ml-1 mr-3 h-5 w-5 text-white"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              v-if="loading"
              viewBox="0 0 24 24"
            >
              <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
              <path
                class="opacity-75"
                fill="currentColor"
                d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
              ></path>
            </svg>
            Generate Reports
          </button>
        </div>
      </form>
    </div>

    <div
      :class="{ hidden: modal.hidden }"
      class="modal fixed w-full h-full top-0 left-0 flex items-center justify-center"
    >
      <div
        @click="() => (modal.hidden = true)"
        class="modal-overlay absolute w-full h-full bg-gray-900 opacity-50"
      ></div>
      <div class="modal-container bg-white w-11/12 md:max-w-md mx-auto rounded shadow-lg z-50 overflow-y-auto">
        <div class="modal-content py-4 text-left px-6">
          <div class="flex justify-between items-center pb-3">
            <p class="text-2xl font-bold">{{ modal.title }}</p>
            <div class="modal-close cursor-pointer z-50" @click="() => (modal.hidden = true)">
              <svg
                @click="onHide"
                class="fill-current text-black"
                xmlns="http://www.w3.org/2000/svg"
                width="18"
                height="18"
                viewBox="0 0 18 18"
              >
                <path
                  d="M14.53 4.53l-1.06-1.06L9 7.94 4.53 3.47 3.47 4.53 7.94 9l-4.47 4.47 1.06 1.06L9 10.06l4.47 4.47 1.06-1.06L10.06 9z"
                ></path>
              </svg>
            </div>
          </div>
          <p>{{ modal.body }}</p>
          <p class="my-2" v-if="modal.filename">
            <a class="underline text-indigo-600" :href="modal.filename" target="_blank">Download a sample</a>
          </p>
          <p class="my-2" v-if="modal.url">
            <a class="underline text-indigo-600" :href="modal.url" target="_blank">Download</a>
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import SelectSession from './SelectSession.vue'
import Error from './Error.vue'

const DEFAULT_STATE = 'SelectSession'

export default {
  components: {
    SelectSession,
    Error,
  },
  data() {
    return {
      refreshingFilesCache: false,
      modal: {
        hidden: true,
        title: '',
        body: '',
        filename: '',
      },
      config: {
        polePosition: 'left',
        includePoints: false,
        includePositionsGainedPerClass: true,
        announcements: '',
        gridAnnouncements: '',
      },
      eventTitle: '',
      eventSubtitle: '',
      axiosInstance: axios.create({ baseURL: window.location.origin }), // 'https://reporting.usacnation.com'
      state: DEFAULT_STATE,
      events: [],
      reports: [],
      downloadLinks: [],
      selectedEventId: null,
      selectedSessionId: null,
      reportsToGenerate: [],
      loading: true,
      error: '',
      sessionTitle: '',
      session: { data: null, loading: true, error: null },
      formData: new FormData(),
      assets: [
        {
          key: 'competitors',
          modal: {
            title: 'Competitors',
            filename: '/samples/competitors.csv',
            body: 'Place all competitors for the event into a group and export this from the Registration tab',
          },
          name: 'Registration > Export all competitors',
        },
        {
          key: 'timing-passings',
          modal: {
            title: 'Passings',
            filename: '/samples/passings.csv',
            body: 'From the timing screen, select Export Passings',
          },
          name: 'Timing > Export passings',
        },
        {
          key: 'processing-laptimes',
          modal: {
            title: 'Laptimes',
            filename: '/samples/laptimes.csv',
            body: 'From the processing screen, select the Lap Times Export',
          },
          name: 'Processing > Lap times Export',
        },
        {
          key: 'pit-stops',
          modal: {
            title: 'Pit stops',
            filename: '/samples/pitstops.csv',
            body: 'From the processing screen, select the Pit Stop Report tab, right click and select "Export Result". Ensure all columns are shown.',
          },
          name: 'Processing > Pit Stop Report',
        },
        {
          key: 'processing-results',
          modal: {
            title: 'Results',
            filename: '/samples/results.csv',
            body: 'From the processing screen, select all columns (have to do for each race type) and export them',
          },
          name: 'Processing > Results Export',
        },
        {
          key: 'processing-starting-grid',
          modal: {
            title: 'Starting Grid',
            filename: '/samples/startinggrid.csv',
            body: 'Create a starting grid and from within the Starting Grid widget, select Starting Grid Export',
          },
          name: 'Processing > Starting Grid Export',
        },
        {
          key: 'crossings',
          modal: {
            title: 'Recorded Crossings',
            filename: '/samples/crossings.csv',
            body: 'From the USAC data relay, export the crossings for a session',
          },
          name: 'Recorded Crossings',
        },
        {
          key: 'custom',
          modal: {
            title: 'Custom',
            filename: '/samples/custom.csv',
            body: 'Create a report from any CSV with headers and rows of data',
          },
          name: 'Custom CSV Report',
        },
      ],
    }
  },
  computed: {
    assetsNeeded() {
      return Array.from(
        this.reportsToGenerate.reduce((acc, filename) => {
          const report = this.reports.find((r) => r.filename === filename)
          if (report && Array.isArray(report.requires)) report.requires.forEach((e) => acc.add(e))
          return acc
        }, new Set())
      )
    },
  },
  created() {
    this.error = ''
    this.loading = true
    this.axiosInstance
      .get('/events')
      .then((response) => {
        this.events = response.data
        this.loading = false
      })
      .catch((error) => {
        console.error(error)
        this.error = error.toString()
        this.loading = false
      })
    this.axiosInstance
      .get('/reports')
      .then((response) => {
        this.reports = response.data
      })
      .catch((error) => {
        console.error(error)
      })
  },
  methods: {
    async refreshFilesCache() {
      try {
        this.refreshingFilesCache = true
        await axios.get(`https://files.usacnation.com/api/files?refresh`)
      } catch (e) {
        console.error(`Error refreshing files cache: ${e.toString}`, e)
      } finally {
        this.refreshingFilesCache = false
      }
    },
    onHelp(modal, assetKey) {
      const url = this.session?.has?.[assetKey]
        ? `/events/${this.selectedEventId}/sessions/${this.selectedSessionId}/assets/${assetKey}`
        : null
      this.modal = {
        ...modal,
        url,
        hidden: false,
      }
    },
    onFilesChange($event) {
      const { name: fieldName, files: fileList } = $event.target

      if (!fileList.length) {
        this.formData.delete(fieldName)
        return
      }

      Array.from(Array(fileList.length).keys()).map((x) => {
        this.formData.set(fieldName, fileList[x], fileList[x].name)
      })
    },

    async onGenerate() {
      const {
        eventTitle,
        eventSubtitle,
        selectedEventId: eventId,
        selectedSessionId: sessionId,
        reportsToGenerate,
        sessionTitle,
      } = this
      const event = this.events.find((e) => e.id === eventId)
      let { leftLogoUrl = '', rightLogoUrl = '', footerLogoUrl = '', filenamePrefix = '' } = event.config || {}

      if ('leftLogoUrl' in this.session.data.session) leftLogoUrl = !this.session.data.session.leftLogoUrl ? '' : this.session.data.session.leftLogoUrl
      if ('rightLogoUrl' in this.session.data.session) rightLogoUrl = !this.session.data.session.rightLogoUrl ? '' : this.session.data.session.rightLogoUrl
      if ('footerLogoUrl' in this.session.data.session) footerLogoUrl = !this.session.data.session.footerLogoUrl ? '' : this.session.data.session.footerLogoUrl

      try {
        this.loading = true
        const url = `/events/${eventId}/sessions/${sessionId}/assets`
        await this.axiosInstance.post(url, this.formData)
        await this.axiosInstance.post(`/events/${eventId}/sessions/${sessionId}`, {
          announcements: this.config.announcements,
          gridAnnouncements: this.config.gridAnnouncements,
          sessionTitle,
        })
        this.formData = new FormData()

        // Create download links
        this.downloadLinks = reportsToGenerate.map((r) => {
          // @todo -- build as query string
          const url = `${this.axiosInstance.defaults.baseURL}/${r}?eventId=${encodeURIComponent(
            eventId
          )}&leftLogoUrl=${encodeURIComponent(leftLogoUrl)}&rightLogoUrl=${encodeURIComponent(
            rightLogoUrl
          )}&footerLogoUrl=${encodeURIComponent(
            footerLogoUrl
          )}&eventTitle=${encodeURIComponent(eventTitle)}&eventSubtitle=${encodeURIComponent(
            eventSubtitle
          )}&sessionId=${encodeURIComponent(sessionId)}&title=${encodeURIComponent(
            sessionTitle
          )}&polePosition=${encodeURIComponent(this.config.polePosition)}&includePositionsGainedPerClass=${
            this.config.includePositionsGainedPerClass ? '1' : ''
          }&includePoints=${this.config.includePoints ? '1' : ''}`
          const name = r

          const report = this.reports.find((report) => report.filename === r)

          // this.session.data.session.seriesName || '', 
          const pdfFilenameWithPrefix = [filenamePrefix || '', report.pdfFilename].join('')

          const pdfUrl = report?.pdfFilename
              ? `https://report-rendering.fly.dev/?filename=${encodeURIComponent(pdfFilenameWithPrefix)}&landscape=${
                  report.landscape ? 'true' : 'false'
                }&url=${encodeURIComponent(window.location.origin + url.replace(this.axiosInstance.defaults.baseURL, ''))}`
              : ''
          return { name, url, pdfUrl }
        })

        // Hacky! Inject second best qualifying link
        const pscQualifying = this.downloadLinks.find(l => l.url.includes('/qualifying-summary.html'))
        if (pscQualifying) {
          const secondBest = JSON.parse(JSON.stringify(pscQualifying))
          secondBest.name = 'Second Best Qualifying Report'
          secondBest.url = secondBest.url.replace('qualifying-summary.html', 'second-best-qualifying-summary.html')
          secondBest.pdfUrl = secondBest.pdfUrl.replace('qualifying-summary.html', 'second-best-qualifying-summary.html')
          this.downloadLinks.push(secondBest)
        }

        // Clear out the file upload boxes
        const names = Array.from(this.formData.keys())
        names.forEach((name) => {
          const elements = document.getElementsByName(name)
          elements.forEach((ele) => (ele.value = null))
        })
      } catch (e) {
        console.error(e)
      } finally {
        this.loading = false
      }

      await this.fetchSession(sessionId)
    },
    onReset() {
      this.state = DEFAULT_STATE
      this.selectedEvent = null
    },
    onSelectEvent(eventId) {
      const event = this.events.find((e) => e.id === eventId)
      this.selectedEventId = eventId
      this.eventTitle = event.config.title
      this.eventSubtitle = event.config.subtitle
      this.formData = new FormData()
    },
    async fetchSession(id) {
      try {
        this.session.error = null
        this.session.data = null
        this.session.has = null
        this.session.loading = true

        const url = `/events/${this.selectedEventId}/sessions/${id}`
        const { data } = await this.axiosInstance.get(url)
        this.session.data = data
        this.session.has = {
          competitors: !!(data.laps || data.results || data.startingGrid),
          'timing-passings': !!data.laps,
          'processing-laptimes': !!data.laps,
          'processing-results': !!data.results,
          'processing-starting-grid': !!data.startingGrid,
          crossings: !!data.crossings,
          custom: !!data.custom,
        }
        if (data.sessionTitle) this.sessionTitle = data.sessionTitle
        this.config.announcements = data.announcements || ''
        this.config.gridAnnouncements = data.gridAnnouncements || ''
      } catch (e) {
        this.session.error = e.toString()
      } finally {
        this.session.loading = false
      }
    },
    async onSelectSession({ id, name }) {
      this.selectedSessionId = id
      this.sessionTitle = name

      this.downloadLinks = []

      this.fetchSession(id)
    },
  },
}
</script>

<style>
.file-input {
  margin: 1em 0;
}
</style>
