import { createApp } from 'vue'
import * as VueRouter from 'vue-router'
import App from './App.vue'
import Main from './components/Main.vue'
import Championships from './components/Championships.vue'
import Gridding from './components/Gridding.vue'
import axios from 'axios'

axios.defaults.baseURL = process.env.NODE_ENV == 'development' ? 'https://brave-deploy-6e5to.cloud.serverless.com' : ''

const routes = [
  { path: '/', component: Main },
  { path: '/championships', component: Championships },
  { path: '/gridding', component: Gridding },
  // { path: '/:slug', component: Main },
]

const router = VueRouter.createRouter({
  history: VueRouter.createWebHistory(),
  routes,
})

createApp(App).use(router).mount('#app')
