<template>
  <div class="w-screen max-w-sm">
    <div class="my-5">
      <div class="text-white text-center my-5" v-if="loading">Loading...</div>
      <div class="text-white text-center my-5" v-else-if="events.length == 0">
        <strong>No events found</strong>
      </div>
      <div class="text-white text-center my-5" v-else-if="error"><Error :message="error" /></div>
      <div v-else class="grid grid-cols-2 gap-4 content-between">
        <div class="">
          <select
            class="form-select appearance-none block w-full px-3 py-1.5 text-base font-normal text-gray-700 bg-white bg-clip-padding bg-no-repeat border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
            aria-label="Select an event"
            v-model="selectedEventId"
            @change="selectEvent"
          >
            <option value="">Select an event</option>
            <option v-for="event in events" :key="event.id" :value="event.id">{{ event.name }}</option>
          </select>
        </div>
        <div class="">
          <select
            class="form-select appearance-none block w-full px-3 py-1.5 text-base font-normal text-gray-700 bg-white bg-clip-padding bg-no-repeat border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
            aria-label="Select a session"
            v-model="selectedSessionId"
            @change="selectSession"
          >
            <option value="">Select a session</option>
            <option v-for="session in sessions" :key="session.id" :value="session.id">{{ session.name }}</option>
          </select>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Error from './Error.vue'

export default {
  components: { Error },
  props: {
    axios: {
      type: Function,
      required: true,
    },
    events: {
      type: Array,
      required: true,
    },
    loading: {
      type: Boolean,
      required: true,
    },
    error: {
      type: String,
      required: false,
    },
  },
  created() {
    // if (this.events.length) {
    //   this.selectedEvent = this.events[0].id
    // }
  },
  data() {
    return {
      selectedEventId: '',
      selectedSessionId: '',
      sessions: null,
    }
  },
  methods: {
    selectSession($event) {
      const sessionId = $event.target.value
      this.sessionId = sessionId
      const session = this.sessions.find((s) => s.id === sessionId)
      this.$emit('session', session)
    },
    selectEvent($event) {
      const eventId = $event.target.value
      this.selectedEventId = eventId
      this.$emit('event', eventId)
      if (!eventId) {
        this.sessions = null
        this.$emit('session', '')
        return
      }
      this.axios
        .get(`/events/${eventId}/sessions`)
        .then((response) => {
          this.sessions = response.data
          // this.loading = false
          this.$emit('sessions', this.sessions)
        })
        .catch((error) => {
          console.error(error)
          // this.error = error.toString()
          // this.loading = false
        })
    },
  },
}
</script>

<style scoped></style>
