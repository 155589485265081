<template>
  <div class="h-screen bg-gradient-to-br from-gray-600 to-gray-800 flex flex-col items-center w-full">
    <div v-if="championships.data">
      <form>
        <Error v-if="championships.error" :message="championships.error" />
        <div class="bg-white px-10 py-8 rounded-none sm:rounded-xl w-screen shadow-md max-w-2xl">
          <div class="space-y-4">
            <h1 class="text-center text-2xl font-semibold text-gray-600">USAC Championship Reporting</h1>
          </div>
          <div v-if="Array.isArray(championships.data) && championships.data.length">
            <label class="block mt-3 mb-1 text-left text-gray-600 font-semibold">Download Reports</label>
            <ul>
              <li v-for="c in championships.data" :key="c.id">
                <a
                  class="text-indigo-600 underline"
                  :href="`/reports/championship.html?championshipId=${c.id.replace('championships:', '')}`"
                  target="_blank"
                  >{{ c.title }}</a
                >
                <a @click.prevent="onGenerate(c.id.replace('championships:', ''))" href="#">Generate</a>
              </li>
            </ul>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import Error from './Error.vue'

export default {
  components: {
    Error,
  },
  data() {
    return {
      championships: {
        data: null,
        error: null,
        loading: false,
      },
    }
  },
  created() {
    this.championships.error = null
    this.championships.loading = true
    axios
      .get('/api/championships')
      .then((response) => {
        this.championships.data = response.data
        this.championships.loading = false
      })
      .catch((error) => {
        console.error(error)
        this.championships.error = error.toString()
        this.championships.loading = false
      })
  },
  methods: {
    async onGenerate(id) {
      await axios.patch(`/api/championships/${id}`, {})
    },
  },
}
</script>

<style></style>
